import { Flex } from '@mantine/core'

import { useScreenSize } from '~/hooks'

import { Typography } from '../shared/text/Typography'
import { StarFilled } from './ReviewIcons'

export const ReviewCardSummary = ({
  rating,
  reviews,
}: {
  rating?: number | null
  reviews?: number | null
}) => {
  const { isMobileScreen } = useScreenSize()
  if (reviews == null || rating == null) {
    return null
  }
  return (
    <Flex
      align="center"
      columnGap={4}
    >
      <StarFilled
        fill="#D66F3D"
        size="18"
      />
      <Typography variant={isMobileScreen ? "caption" : "body3"}>{rating.toFixed(1)}</Typography>
      <Typography
        color="appDescription.0"
        variant={isMobileScreen ? "caption" : "body3"}
      >
        ({reviews})
      </Typography>
    </Flex>
  )
}
