import { Flex } from '@mantine/core'

import { Typography } from '../shared/text/Typography'

const GuideTypeTag = ({ type }: { type?: string | null }) => {
  if (!type) {
    return null
  }

  const getTagBGColor = () => {
    switch (type) {
      case 'destination-guide':
        return 'appYellow.0'
      case 'itinerary':
        return 'appMint.0'
      case 'map-or-list':
        return 'appPink.0'
      default:
        return 'appIce.0'
    }
  }

  const getTagLabel = () => {
    switch (type) {
      case 'destination-guide':
        return 'Guide'
      case 'itinerary':
        return 'Itinerary'
      case 'map-or-list':
        return 'Map'
      default:
        return 'DIY Tour'
    }
  }

  return (
    <Flex
      p={2}
      bg={getTagBGColor()}
      w="min-content"
      justify="center"
      align="center"
      sx={{ borderRadius: 4, position: 'absolute', top: 6, right: 6, zIndex: 2 }}
    >
      <Typography
        variant="tag"
        sx={{ whiteSpace: 'nowrap' }}
      >
        {getTagLabel()}
      </Typography>
    </Flex>
  )
}

export default GuideTypeTag
