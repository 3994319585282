import { Box, Flex } from '@mantine/core'
import { IconPlayerPlay } from '@tabler/icons'
import { useState, useRef, useEffect } from 'react'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { BoardSummaryType } from '~/endpoints/model'
import { GuideVideoModal } from './GuideVideoModal'
import { getVideoUrl } from '~/components/shared/video'
import { Typography } from '~/components/shared/text/Typography'
import { GuideCardImage } from './GuideCardImage'

export function GuideCardImageWithVideo({ guide, w = 222, h = 296, play = true, alwaysShowButton = false, neverShowButton = false, noImageFallback=false }: { guide: BoardSummaryType, w?: number, h?: number, play?: boolean, alwaysShowButton?: boolean, neverShowButton?: boolean, noImageFallback?: boolean }) {
    const [showIntroVideoModal, setShowIntroVideoModal] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null)

    const {
        coverImage,
        coverVideo
    } = guide

    useEffect(() => {
        if (play && videoRef.current) {
            videoRef.current.play()
        } else if (videoRef.current) {
            videoRef.current.pause()
        }
    }, [play])

    return (<>
        <Box sx={{ position: 'relative', width: w, height: h }} className='group'>
            {coverVideo && <video muted loop playsInline className={`object-cover transition-opacity`} style={{ width: w, height: h, zIndex: 10, position:'absolute', top: 0, left: 0, opacity: play ? 1 : 0.01 }} ref={videoRef}>
                <source type="video/mp4" src={getVideoUrl(coverVideo)} />
            </video>}
            <GuideCardImage src={noImageFallback ? "" : (coverImage ?? "")} imageHeight={h} imageWidth={w} />
            {coverVideo && !neverShowButton && (
                <Flex sx={{ zIndex: 11 }} justify="center" className={`absolute w-full h-full md:h-auto md:bottom-3 items-center ${alwaysShowButton ? '' : 'md:opacity-0 group-hover:opacity-100'}`}>
                    <ThatchButton
                        className='w-10 h-10 rounded-full md:w-auto md:h-unset md:rounded-unset'
                        style={{
                            background: 'rgba(255, 255, 255, 0.7)',
                        }}
                        label={
                            <Typography variant='button_xsmall' className='hidden md:block'>Watch Trailer</Typography>
                        }
                        onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setShowIntroVideoModal(true)
                        }}
                        size="extraSmall"
                        variant="white"
                        leftIcon={
                            <IconPlayerPlay
                                fill="black"
                                height={18}
                                width={18}
                                className='ml-4 md:ml-0'
                            />
                        }
                    />
                </Flex>
            )}
        </Box>
        {showIntroVideoModal && coverVideo && (
            <GuideVideoModal
                opened={showIntroVideoModal}
                setOpened={setShowIntroVideoModal}
                videoUrl={coverVideo}
                guide={guide}
                // profile={profile}
            />
        )}
    </>)
}